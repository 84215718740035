<!-- result-cooperations -->
<template>
  <div class="result-cooperations">
    <!-- 标题 -->
    <div class="cooperations-title">
      <custom-subtitle title="合作记录" />
    </div>

    <div class="cooperations-content">
      <el-table
        :data="formattedCooperations"
        size="small"
        show-summary
        :summary-method="summaryMethod"
      >
        <el-table-column label="签约时间" prop="signTime" width="150px" />

        <el-table-column label="游戏名称" prop="gameName" />

        <el-table-column label="排期时间" prop="scheduleTime" width="160px" />

        <el-table-column label="合作方式">
          <template v-slot="{ row: { cooperationType } }">
            <div>{{ cooperationType | cooperationTypeText }}</div>
          </template>
        </el-table-column>

        <el-table-column label="开播天数">
          <template v-slot="{ row }">
            <div>{{ row | broadcastedCountAndPercentage }}</div>
          </template>
        </el-table-column>

        <el-table-column label="流水数" prop="cooperationIncome">
          <template v-slot="{ row: { status, cooperationIncome } }">
            <div>
              {{
                status === cooperationStatusEnum.signed
                  ? '-'
                  : cooperationIncome
              }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="注册数" prop="cooperationRegistration">
          <template v-slot="{ row: { status, cooperationRegistration } }">
            <div>
              {{
                status === cooperationStatusEnum.signed
                  ? '-'
                  : cooperationRegistration
              }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="支出数" prop="cooperationExpenditure">
          <template v-slot="{ row: { status, cooperationExpenditure } }">
            <div>
              {{
                status === cooperationStatusEnum.signed
                  ? '-'
                  : cooperationExpenditure
              }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="毛利润" prop="profit">
          <template v-slot="{ row: { status, profit } }">
            <div>
              {{ status === cooperationStatusEnum.signed ? '-' : profit }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="ROI">
          <template v-slot="{ row: { status, roi } }">
            <!-- 未排期 -->
            <div v-if="status === cooperationStatusEnum.signed">-</div>

            <!-- 已排期、已完成 -->
            <roi v-else :value="roi" disable-transitions />
          </template>
        </el-table-column>

        <el-table-column label="商务" prop="username" />

        <el-table-column label="完成时间" width="150px">
          <template v-slot="{ row: { status, finishTime } }">
            <div>
              {{
                status === cooperationStatusEnum.completed ? finishTime : '-'
              }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="50px">
          <template v-slot="{ row }">
            <div class="table-row-operate">
              <el-button
                plain
                type="primary"
                icon="el-icon-view"
                circle
                size="mini"
                @click="handleTableRowViewClick(row)"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import CustomSubtitle from '../../../../../components/custom-subtitle';
  import Roi from '../../../../../components/roi';
  import cooperationType from '../../../../../enum/cooperation-type.js';
  import cooperationStatus from '../../../../../enum/cooperation-status.js';

  export default {
    name: 'result-cooperations',
    components: {
      CustomSubtitle,
      Roi,
    },
    filters: {
      // 合作方式文案
      cooperationTypeText(val) {
        return cooperationType.map[val] || '';
      },
      // 开播天数及开播率文案
      broadcastedCountAndPercentage(row) {
        const { signDays, broadcastedCount } = row;
        const broadcastedPercentage = signDays
          ? Math.round((broadcastedCount / signDays) * 100)
          : 100;

        return `${broadcastedCount} (${broadcastedPercentage}%)`;
      },
    },
    computed: {
      ...mapState('library/anchor', ['operateAnchorCooperations']),

      // 合作状态枚举值
      cooperationStatusEnum() {
        return cooperationStatus.enum;
      },
      // 格式化合作列表
      formattedCooperations() {
        return this.operateAnchorCooperations.map(item => {
          const {
            cooperationIncome = 0,
            cooperationRegistration = 0,
            cooperationExpenditure = 0,
          } = item;
          const profit = cooperationIncome - cooperationExpenditure;

          item.cooperationIncome = cooperationIncome;
          item.cooperationRegistration = cooperationRegistration;
          item.cooperationExpenditure = cooperationExpenditure;
          item.profit = profit;
          item.roi = cooperationExpenditure
            ? profit / cooperationExpenditure
            : cooperationIncome;

          return item;
        });
      },
    },
    methods: {
      // 计算合计的方法
      summaryMethod({ columns, data }) {
        const sums = [];

        columns.forEach((col, index) => {
          if (index === 0) {
            sums[index] = '合计';

            return;
          }

          if (index === 5 || index === 6 || index === 7 || index === 8) {
            const values = data.map(item => parseInt(item[col.property]) || 0);

            if (values.some(val => isNaN(val))) {
              sums[index] = 'N/A';
            } else {
              sums[index] = values.reduce((prev, curr) => prev + curr, 0);
            }

            return;
          }

          sums[index] = '-';
        });

        return sums;
      },
      // 表格行查看按钮单击
      handleTableRowViewClick({ _id }) {
        this.operateId = _id;

        this.$router.push({
          name: 'anchor-cooperation-view',
          params: { id: _id },
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .result-cooperations {
    // 内容
    .cooperations-content {
      margin-top: 10px;

      // 操作按钮
      .table-row-operate {
        .el-button {
          min-width: auto;
        }
      }
    }
  }
</style>
