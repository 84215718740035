<!-- result-contacts -->
<template>
  <div class="result-contacts">
    <!-- 标题 -->
    <div class="contacts-title">
      <custom-subtitle title="跟进信息" />
    </div>

    <!-- 内容 -->
    <div class="contacts-content">
      <el-timeline
        v-if="operateAnchorFollowContacts && operateAnchorFollowContacts.length"
        class="content-timeline"
      >
        <el-timeline-item
          v-for="contact in operateAnchorFollowContacts"
          placement="top"
          :type="
            contact.result === followContactResultEnum.ok
              ? 'success'
              : 'warning'
          "
          :icon="
            contact.result === followContactResultEnum.ok
              ? 'el-icon-success'
              : 'el-icon-info'
          "
          :timestamp="contact.createTime"
          :key="contact._id"
        >
          <!-- 时间线项内容容器 -->
          <el-card class="timeline-item" shadow="never">
            <div class="item-title" v-html="genTimelineTitle(contact)" />
            <div class="item-content">{{ contact.content }}</div>
          </el-card>
        </el-timeline-item>
      </el-timeline>

      <empty-placeholder v-else />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import CustomSubtitle from '../../../../../components/custom-subtitle';
  import EmptyPlaceholder from '../../../../../components/empty-placeholder';
  import followStatus from '../../../../../enum/follow-status';
  import followContactResult from '../../../../../enum/follow-contact-result';

  export default {
    name: 'result-contacts',
    components: {
      CustomSubtitle,
      EmptyPlaceholder,
    },
    computed: {
      ...mapState('library/anchor', ['operateAnchorFollowContacts']),

      // 跟进状态枚举值
      followStatusEnum() {
        return followStatus.enum;
      },
      // 联系结果枚举值
      followContactResultEnum() {
        return followContactResult.enum;
      },
    },
    methods: {
      // 生成时间线 title
      genTimelineTitle(contact) {
        const {
          username = '',
          typeName = '',
          number = '',
          result,
          resultName = '',
        } = contact;
        const followContactResultOkValue = followContactResult.enum.ok;
        const followContactResultOkText =
          followContactResult.map[followContactResultOkValue];

        return `<span class="highlight">${username}</span>通过<span class="highlight">${typeName}(${number})</span>与主播进行联系，联系结果为:<span class="highlight">${
          result === followContactResultOkValue
            ? followContactResultOkText
            : resultName
        }</span>。具体联系内容如下:`;
      },
    },
  };
</script>

<style scoped lang="scss">
  .result-contacts {
    // 内容
    .contacts-content {
      margin: 10px 0 0 10px;

      // 时间线容器
      /deep/ .content-timeline {
        // 时间线项
        .timeline-item {
          line-height: 22px;
          font-size: 13px;
          color: #777;

          .item-title {
            > .highlight {
              padding: 0 5px;
              color: #409eff;

              &:nth-of-type(1) {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }
</style>
