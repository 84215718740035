// 跟进状态
export default {
  map: {
    1: '跟进中',
    2: '已完成',
  },
  enum: {
    following: 1,
    followed: 2,
  },
};
