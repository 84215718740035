// 主播状态(阶段)
export default {
  map: {
    1: '跟进中',
    2: '闲置中',
    3: '合作中',
  },
  enum: {
    following: 1,
    idling: 2,
    cooperating: 3,
  },
};
