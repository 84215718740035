// 主播归属类型
export default {
  map: {
    1: '个人',
    2: '分组',
    3: '公共',
  },
  enum: {
    user: 1,
    group: 2,
    public: 3,
  },
};
