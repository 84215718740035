<template>
  <div class="custom-title" :style="customTitleStyle">
    {{ title }}
  </div>
</template>

<script>
  // 标题字体大小映射
  const titleFontSizeMap = {
    large: 18,
    medium: 16,
    small: 14,
    mini: 12,
  };

  export default {
    name: 'custom-title',
    props: {
      // 标题文案
      title: {
        type: String,
        default: '标题',
      },
      // 标题大小
      size: {
        type: String,
        validator: val => titleFontSizeMap.hasOwnProperty(val),
        default: 'small',
      },
    },
    computed: {
      customTitleStyle() {
        return {
          fontSize: `${titleFontSizeMap[this.size]}px`,
        };
      },
    },
  };
</script>

<style scoped lang="scss">
  .custom-title {
    display: inline-flex;
    align-items: center;
    margin: 0;
    color: #303133;

    &::before {
      content: ' ';
      display: inline-block;
      width: 3px;
      height: 12px;
      border-radius: 1px;
      margin-right: 8px;
      background-color: #409eff;
    }
  }
</style>
