<!-- library-anchor -->
<template>
  <div class="library-anchor" :class="{ seeking }" :style="containerStyle">
    <!-- 查询表单 -->
    <div class="library-anchor-form">
      <el-form
        ref="form"
        :model="model"
        :rules="rules"
        label-width="0"
        @submit.native.prevent
      >
        <el-form-item prop="keyword">
          <el-input
            ref="input"
            v-model="model.keyword"
            :placeholder="searchPlaceholder"
            clearable
            @keyup.native.enter="handleFormSearchClick"
            @clear="handleFormSearchClearClick"
          >
            <template v-slot:prepend>
              <el-select v-model="model.type">
                <el-option label="全部" :value="0" />

                <el-option
                  v-for="item in anchorBelongsTypeList"
                  :label="item.label"
                  :value="item.value"
                  :key="item.value"
                />
              </el-select>
            </template>

            <template v-slot:append>
              <el-button
                icon="el-icon-search"
                :loading="searching"
                @click="handleFormSearchClick"
              />
            </template>
          </el-input>
        </el-form-item>

        <el-form-item v-if="routeQueryKeyword" class="form-operate">
          <el-button size="small" icon="el-icon-back" @click="handleBackClick"
            >返回</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- 搜索历史 -->
    <div v-if="!seeking" class="library-anchor-history">
      <anchor-history @on-click="handleHistoryItemClick" />
    </div>

    <!-- 查询结果 -->
    <div v-if="seeking" class="library-anchor-result">
      <anchor-result />
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex';
  import AnchorHistory from './anchor-history';
  import AnchorResult from './anchor-result';
  import { historyLimit } from '../../../config/library-anchor-history.js';
  import anchorBelongsType from '../../../enum/anchor-belongs-type.js';

  export default {
    name: 'library-anchor',
    components: {
      AnchorHistory,
      AnchorResult,
    },
    data() {
      // 关键词校验
      const keywordValidator = (rule, value, callback) => {
        const { type } = this.model;

        // 选择的'全部'且关键词为空，则提示错误
        !type && !value ? callback(new Error(' ')) : callback();
      };

      return {
        height: 0,
        model: null,
        rules: {
          keyword: [{ validator: keywordValidator, trigger: 'change' }],
        },
        searching: false,
        // 是否在执行搜索操作
        seeking: false,
      };
    },
    computed: {
      ...mapState('library/anchor', ['searchHistories']),

      containerStyle() {
        return { height: `${this.height}px` };
      },
      // 主播归属类型列表
      anchorBelongsTypeList() {
        const { map } = anchorBelongsType;

        return Object.entries(map).map(([value, key]) => ({
          label: key,
          value: +value,
        }));
      },
      // 搜索框占位符文案
      searchPlaceholder() {
        const { type } = this.model;

        return type === anchorBelongsType.enum.group
          ? `主播名称、直播间号、分组`
          : `主播名称、直播间号、商务`;
      },
      // 路由查询参数中的关键词
      routeQueryKeyword() {
        const { query: { keyword = '' } = {} } = this.$route;

        return decodeURIComponent(keyword);
      },
    },
    created() {
      this.calcContainerHeight();
      this.registerWindowResize();
      this.initModel();
    },
    mounted() {
      this.focusFormSearchInput();
    },
    activated() {
      const routeQueryKeyword = this.routeQueryKeyword;

      if (!routeQueryKeyword) return;

      this.model.keyword = routeQueryKeyword;

      this.handleFormSearchClick();
    },
    methods: {
      ...mapMutations('library/anchor', [
        'mttSearchHistories',
        'mttSearchParams',
      ]),
      ...mapActions('library/anchor', ['actAnchors']),

      // 计算页面高度
      calcContainerHeight() {
        this.height = window.innerHeight - 168;
      },
      // 窗口大小改变事件
      registerWindowResize() {
        window.addEventListener('resize', this.calcContainerHeight);

        this.$on('hook:beforeDestroy', () =>
          window.removeEventListener('resize', this.calcContainerHeight)
        );
      },
      // 初始化表单模型
      initModel() {
        this.model = {
          // 默认 0，表示'全部'
          type: 0,
          keyword: '',
        };
      },
      // 校验模型
      async validateModel() {
        try {
          return await this.$refs.form.validate();
        } catch (e) {
          return false;
        }
      },
      // 表单搜索框聚焦
      focusFormSearchInput() {
        this.$nextTick(() => {
          const inputCom = this.$refs.input;

          if (!inputCom) return;

          const inputEl = inputCom.$el.querySelector('input');

          inputEl && inputEl.focus();
        });
      },
      // 新增主播库搜索历史
      addLibraryAnchorHistory(history) {
        const histories = [...this.searchHistories];
        const historiesLength = histories.length;

        // 达到限制数量时，删除最早的搜索历史
        historiesLength >= historyLimit &&
          histories.splice(historiesLength - 1, 1);

        const matchedIndex = histories.findIndex(item => item === history);

        // 已存在，且是第一项
        if (matchedIndex === 0) return;

        // 已存在，且非第一项
        matchedIndex > 0 && histories.splice(matchedIndex, 1);

        // 新增为第一项
        histories.unshift(history);

        this.mttSearchHistories(histories);
      },
      // 获取主播列表
      async getAnchors() {
        this.searching = true;

        await this.actAnchors();

        this.searching = false;
        this.seeking = true;
      },
      // 表单搜索按钮单击
      async handleFormSearchClick() {
        const isValid = await this.validateModel();

        if (!isValid) return;

        const { keyword } = this.model;

        this.getAnchors();

        if (!keyword) return;

        this.addLibraryAnchorHistory(keyword);
      },
      // 表单清空按钮单击
      handleFormSearchClearClick() {
        this.seeking = false;
      },
      // 搜索历史项单击
      handleHistoryItemClick(item) {
        this.model.keyword = item;

        this.getAnchors();
      },
      // 返回按钮单击
      handleBackClick() {
        this.$router.go(-1);
      },
    },
    watch: {
      model: {
        deep: true,
        handler(n) {
          this.mttSearchParams(n);
        },
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../style/mixin.scss';

  .library-anchor {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding-top: 200px;
    overflow: hidden;
    transition: padding-top 0.5s ease-out;

    &.seeking {
      padding-top: 0;
    }

    // 查询表单
    .library-anchor-form {
      width: 800px;

      /deep/.el-select .el-input {
        width: 80px;
      }

      // 操作栏
      .form-operate {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    // 搜索历史
    .library-anchor-history {
      @include fadein();

      width: 800px;
    }

    // 查询结果
    .library-anchor-result {
      @include fadein();

      display: flex;
      flex-grow: 1;
      width: 100%;
      overflow: hidden;
    }
  }
</style>
