<!-- roi -->
<template>
  <el-tag
    class="roi"
    :type="roiTagType"
    :size="size"
    :effect="effect"
    :disable-transitions="disableTransitions"
  >
    {{ roiToFixed }}
  </el-tag>
</template>

<script>
  export default {
    name: 'roi',
    props: {
      value: {
        type: Number,
        default: 0,
      },
      // 尺寸
      size: {
        type: String,
        default: 'mini',
      },
      // 主题
      effect: {
        type: String,
        default: 'plain',
      },
      // 是否禁用渐变动画
      disableTransitions: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      // 保留两位小数
      roiToFixed() {
        const roi = this.value;

        return roi.toFixed(2);
      },
      // roi 标签类型
      roiTagType() {
        const roi = this.value;

        if (roi > 1) return 'success';

        if (roi > 0.5) return 'primary';

        if (roi >= 0) return 'waring';

        return 'danger';
      },
    },
  };
</script>
