var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"result-cooperations"},[_c('div',{staticClass:"cooperations-title"},[_c('custom-subtitle',{attrs:{"title":"合作记录"}})],1),_c('div',{staticClass:"cooperations-content"},[_c('el-table',{attrs:{"data":_vm.formattedCooperations,"size":"small","show-summary":"","summary-method":_vm.summaryMethod}},[_c('el-table-column',{attrs:{"label":"签约时间","prop":"signTime","width":"150px"}}),_c('el-table-column',{attrs:{"label":"游戏名称","prop":"gameName"}}),_c('el-table-column',{attrs:{"label":"排期时间","prop":"scheduleTime","width":"160px"}}),_c('el-table-column',{attrs:{"label":"合作方式"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cooperationType = ref.row.cooperationType;
return [_c('div',[_vm._v(_vm._s(_vm._f("cooperationTypeText")(cooperationType)))])]}}])}),_c('el-table-column',{attrs:{"label":"开播天数"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(_vm._f("broadcastedCountAndPercentage")(row)))])]}}])}),_c('el-table-column',{attrs:{"label":"流水数","prop":"cooperationIncome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var status = ref_row.status;
var cooperationIncome = ref_row.cooperationIncome;
return [_c('div',[_vm._v(" "+_vm._s(status === _vm.cooperationStatusEnum.signed ? '-' : cooperationIncome)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"注册数","prop":"cooperationRegistration"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var status = ref_row.status;
var cooperationRegistration = ref_row.cooperationRegistration;
return [_c('div',[_vm._v(" "+_vm._s(status === _vm.cooperationStatusEnum.signed ? '-' : cooperationRegistration)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"支出数","prop":"cooperationExpenditure"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var status = ref_row.status;
var cooperationExpenditure = ref_row.cooperationExpenditure;
return [_c('div',[_vm._v(" "+_vm._s(status === _vm.cooperationStatusEnum.signed ? '-' : cooperationExpenditure)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"毛利润","prop":"profit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var status = ref_row.status;
var profit = ref_row.profit;
return [_c('div',[_vm._v(" "+_vm._s(status === _vm.cooperationStatusEnum.signed ? '-' : profit)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"ROI"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var status = ref_row.status;
var roi = ref_row.roi;
return [(status === _vm.cooperationStatusEnum.signed)?_c('div',[_vm._v("-")]):_c('roi',{attrs:{"value":roi,"disable-transitions":""}})]}}])}),_c('el-table-column',{attrs:{"label":"商务","prop":"username"}}),_c('el-table-column',{attrs:{"label":"完成时间","width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var status = ref_row.status;
var finishTime = ref_row.finishTime;
return [_c('div',[_vm._v(" "+_vm._s(status === _vm.cooperationStatusEnum.completed ? finishTime : '-')+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"table-row-operate"},[_c('el-button',{attrs:{"plain":"","type":"primary","icon":"el-icon-view","circle":"","size":"mini"},on:{"click":function($event){return _vm.handleTableRowViewClick(row)}}})],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }