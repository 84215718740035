<!-- anchor-history -->
<template>
  <div class="anchor-history">
    <template v-if="searchHistories && searchHistories.length">
      <!-- 标题 -->
      <div class="history-title">搜索历史:</div>

      <!-- 列表 -->
      <div class="history-list">
        <el-tag
          class="history-item"
          v-for="(item, index) in searchHistories"
          :key="item"
          effect="plain"
          size="small"
          closable
          @click="handleHistoryItemClick(item, index)"
          @close="handleHistoryItemCloseClick(index)"
          >{{ item }}
        </el-tag>
      </div>
    </template>

    <empty-placeholder v-else placeholder="暂无搜索历史" />
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import EmptyPlaceholder from '../../../../components/empty-placeholder';

  export default {
    name: 'anchor-history',
    components: {
      EmptyPlaceholder,
    },
    computed: {
      ...mapState('library/anchor', ['searchHistories']),
    },
    methods: {
      ...mapMutations('library/anchor', ['mttSearchHistories']),

      // 搜索历史项单击
      handleHistoryItemClick(item, index) {
        this.$emit('on-click', item);

        if (index === 0) return;

        const histories = [...this.searchHistories];

        histories.splice(index, 1);
        histories.unshift(item);

        this.mttSearchHistories(histories);
      },
      // 搜索历史项关闭按钮单击
      handleHistoryItemCloseClick(index) {
        const histories = [...this.searchHistories];

        histories.splice(index, 1);

        this.mttSearchHistories(histories);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../.././../style/mixin.scss';

  .anchor-history {
    display: flex;
    font-size: 13px;

    // 标题
    .history-title {
      flex: 0 0 60px;
      line-height: 24px;
    }

    // 列表
    .history-list {
      flex-grow: 1;
      margin-top: -10px;

      .history-item {
        @include fadein();

        margin: 10px 0 0 10px;
        cursor: pointer;
      }
    }
  }
</style>
