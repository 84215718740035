<!-- anchor-result -->
<template>
  <div class="anchor-result" :class="{ empty: !isAnchorsNonEmpty }">
    <template v-if="isAnchorsNonEmpty">
      <div class="result-left">
        <result-anchors />
      </div>

      <el-scrollbar class="result-right" v-loading="loading">
        <!-- 跟进记录 -->
        <div class="result-contacts-container">
          <result-contacts />
        </div>

        <!-- 合作历史 -->
        <div class="result-cooperations-container">
          <result-cooperations />
        </div>
      </el-scrollbar>
    </template>

    <template v-else>
      <empty-placeholder />
    </template>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import ResultAnchors from './result-anchors';
  import ResultContacts from './result-contacts';
  import ResultCooperations from './result-cooperations';
  import EmptyPlaceholder from '../../../../components/empty-placeholder';

  export default {
    name: 'anchor-result',
    components: {
      ResultAnchors,
      ResultContacts,
      ResultCooperations,
      EmptyPlaceholder,
    },
    data() {
      return {
        loading: false,
      };
    },
    computed: {
      ...mapState('library/anchor', ['anchors', 'operateAnchorId']),

      // 主播列表是否非空
      isAnchorsNonEmpty() {
        const anchors = this.anchors;

        return anchors && anchors.length;
      },
    },
    methods: {
      ...mapActions('library/anchor', [
        'actAnchorFollowContacts',
        'actAnchorCooperations',
      ]),

      // 获取主播跟进联系记录列表和合作历史列表
      async getAnchorFollowContactsAndCooperations() {
        this.loading = true;

        await Promise.all([
          this.actAnchorFollowContacts(),
          this.actAnchorCooperations(),
        ]);

        this.loading = false;
      },
    },
    watch: {
      operateAnchorId: {
        immediate: true,
        handler(n) {
          if (!n) return;

          this.getAnchorFollowContactsAndCooperations();
        },
      },
    },
  };
</script>

<style scoped lang="scss">
  .anchor-result {
    display: flex;
    flex-grow: 1;

    &.empty {
      padding-left: 0;
    }

    .result-left {
      display: flex;
      flex: 0 0 320px;
    }

    /deep/ .result-right {
      flex-grow: 1;
      height: 100%;
      padding-right: 15px;
      margin-left: 20px;
      margin-right: -15px;

      .el-scrollbar__wrap {
        overflow: hidden auto;
      }

      .result-cooperations-container {
        margin-top: 10px;
      }
    }
  }
</style>
