<!-- result-anchors -->
<template>
  <div class="result-anchors">
    <!-- 标题 -->
    <div class="anchors-title">
      <custom-title title="主播列表" />
    </div>

    <!-- 内容 -->
    <div class="anchors-content">
      <div v-if="anchorSummarizedByStatus" class="content-summary">
        <div
          class="summary-item summary-cooperating"
          :class="{ active: status === anchorStatusEnum.cooperating }"
          @click="handleSummaryItemClick(anchorStatusEnum.cooperating)"
        >
          <span>合作中:</span
          ><span>{{ anchorSummarizedByStatus.cooperating }}</span>
        </div>

        <div
          class="summary-item summary-idling"
          :class="{ active: status === anchorStatusEnum.idling }"
          @click="handleSummaryItemClick(anchorStatusEnum.idling)"
        >
          <span>闲置中:</span><span>{{ anchorSummarizedByStatus.idling }}</span>
        </div>

        <div
          class="summary-item summary-following"
          :class="{ active: status === anchorStatusEnum.following }"
          @click="handleSummaryItemClick(anchorStatusEnum.following)"
        >
          <span>跟进中:</span
          ><span>{{ anchorSummarizedByStatus.following }}</span>
        </div>
      </div>

      <el-scrollbar class="content-list">
        <el-card
          class="anchor-item"
          :class="{ active: operateAnchorId === anchor._id }"
          v-for="anchor in anchorsFilteredByStatus"
          shadow="never"
          :key="anchor._id"
          @click.native="handleItemClick(anchor)"
        >
          <div class="item-info item-name">
            <div class="name-content">{{ anchor.name }}</div>

            <div v-if="currentUserHasFullPermissions" class="name-assign">
              <el-button
                plain
                type="primary"
                icon="el-icon-thumb"
                circle
                size="mini"
                :disabled="anchor.status === anchorStatusEnum.cooperating"
                @click.native.stop
                @click="handleItemAssignClick(anchor)"
              />
            </div>
          </div>

          <div class="item-info">
            <span class="item-label">直播平台:</span>
            <span class="item-value">{{ anchor.platformName }}</span>
          </div>

          <div class="item-info">
            <span class="item-label">直播间号:</span>
            <span class="item-value">{{ anchor.roomNo }}</span>
          </div>

          <div class="item-info">
            <span class="item-label">游戏板块:</span>
            <span class="item-value">{{ anchor.categoryName }}</span>
          </div>

          <div class="item-info">
            <span class="item-label">主播状态:</span>
            <span class="item-value">
              <el-tag
                :type="anchor.status | anchorStatusTagType"
                size="mini"
                effect="plain"
              >
                {{ anchor.status | anchorStatusText }}
              </el-tag>
            </span>
          </div>

          <div
            class="item-info item-belongs"
            :class="{
              user: !!anchor.userId,
              group: !!anchor.groupId,
              public: !anchor.userId && !anchor.groupId,
            }"
          >
            <span class="item-label">主播归属:</span>
            <span class="item-value">{{ anchor | anchorBelongsText }}</span>
          </div>
        </el-card>

        <empty-placeholder v-if="!anchorsFilteredByStatus.length" :zoom="0.6" />
      </el-scrollbar>
    </div>

    <!-- 分配主播对话框 -->
    <anchor-assign
      v-model="showAssignDialog"
      @on-ok="handleAssignDialogOkClick"
    />
  </div>
</template>

<script>
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
  import CustomTitle from '../../../../../components/custom-title';
  import EmptyPlaceholder from '../../../../../components/empty-placeholder';
  import anchorStatus from '../../../../../enum/anchor-status.js';
  import anchorBelongsType from '../../../../../enum/anchor-belongs-type.js';

  export default {
    name: 'result-anchors',
    components: {
      CustomTitle,
      EmptyPlaceholder,
      AnchorAssign: () =>
        import(
          /* webpackChunkName: 'library-anchor-assign' */ './anchor-assign'
        ),
    },
    filters: {
      // 主播状态标签类型
      anchorStatusTagType(val) {
        const tagTypes = {
          [anchorStatus.enum.following]: 'warning',
          [anchorStatus.enum.idling]: 'primary',
          [anchorStatus.enum.cooperating]: 'success',
        };

        return tagTypes[val];
      },
      // 主播状态文案
      anchorStatusText(val) {
        return anchorStatus.map[val] || '';
      },
      // 主播归属文案
      anchorBelongsText(anchor) {
        const { userId, username, groupId, groupName = '' } = anchor;
        const formattedGroupName = groupName.endsWith('组')
          ? groupName
          : `${groupName}组`;

        return userId
          ? username
          : groupId
          ? formattedGroupName
          : anchorBelongsType.map[anchorBelongsType.enum.public];
      },
    },
    data() {
      return {
        // 当前选择的主播状态
        status: 0,
        // 是否显示分配主播对话框
        showAssignDialog: false,
      };
    },
    computed: {
      ...mapState('library/anchor', ['anchors', 'operateAnchorId']),
      ...mapGetters(['currentUserHasFullPermissions']),

      // 主播状态枚举值
      anchorStatusEnum() {
        return anchorStatus.enum;
      },
      // 合作中、闲置中、跟进中主播个数
      anchorSummarizedByStatus() {
        const anchors = this.anchors;

        if (!anchors || !anchors.length) return null;

        const summary = { cooperating: 0, idling: 0, following: 0 };

        anchors.forEach(anchor => {
          const { status } = anchor;

          if (status === anchorStatus.enum.cooperating) {
            summary.cooperating++;

            return;
          }

          if (status === anchorStatus.enum.idling) {
            summary.idling++;

            return;
          }

          summary.following++;
        });

        return summary;
      },
      // 当前按状态过滤的主播列表
      anchorsFilteredByStatus() {
        const anchors = this.anchors;
        const status = this.status;

        if (!status) return anchors;

        return anchors.filter(item => item.status === status);
      },
    },
    methods: {
      ...mapMutations('library/anchor', [
        'mttAnchors',
        'mttOperateAnchorId',
        'mttAssignAnchorObj',
      ]),
      ...mapActions('library/anchor', ['actAnchors']),

      // 统计项单击
      handleSummaryItemClick(status) {
        const currStatus = this.status;

        this.status = status === currStatus ? 0 : status;
      },
      // 列表项单击
      handleItemClick({ _id }) {
        this.mttOperateAnchorId(_id);
      },
      // 列表项分配按钮单击
      handleItemAssignClick(anchor) {
        const { status } = anchor;

        if (status === anchorStatus.enum.cooperating) {
          this.$message.error('主播状态为【合作中】，禁止分配');

          return;
        }

        this.mttAssignAnchorObj(anchor);

        this.showAssignDialog = true;
      },
      // 分配对话框确定按钮执行完成
      handleAssignDialogOkClick() {
        this.actAnchors();
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../../../style/mixin.scss';

  .result-anchors {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    // 内容
    .anchors-content {
      position: relative;
      flex-grow: 1;
      margin-top: 10px;
      border-radius: 3px;
      background-color: #fbfcfe;
      box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
      overflow: hidden;

      .content-summary {
        position: absolute;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 40px;
        padding: 0 10px;
        background-color: #fff;
        font-size: 13px;
        box-shadow: 0 0 3px rgba(29, 35, 41, 0.1);

        .summary-item {
          position: relative;
          cursor: pointer;

          &::after {
            content: ' ';
            position: absolute;
            left: 50%;
            bottom: -11px;
            width: 18px;
            height: 2px;
            margin-left: -9px;
            border-radius: 1px;
            background-color: transparent;
            transition: background-color 0.3s linear;
          }

          &.active::after {
            background-color: #409eff;
          }
        }

        .summary-cooperating span:nth-last-of-type(1) {
          color: #67c23a;
        }

        .summary-idling span:nth-last-of-type(1) {
          color: #409eff;
        }

        .summary-following span:nth-last-of-type(1) {
          color: #e6a23c;
        }

        span:nth-last-of-type(1) {
          font-weight: 700;
          margin-left: 5px;
          text-decoration: underline;

          &::after {
            content: '个';
          }
        }
      }

      /deep/ .content-list {
        height: 100%;
        padding-top: 40px;

        .el-scrollbar__wrap {
          padding: 8px;
          overflow: hidden auto;
        }

        .anchor-item {
          @include fadein();

          cursor: pointer;
          transition: border-color 0.3s;

          &.active {
            border-color: #409eff;
          }

          & + .anchor-item {
            margin-top: 10px;
          }

          .item-info {
            display: inline-block;
            width: 50%;
            line-height: 22px;
            font-size: 12px;

            &.item-name {
              display: flex;
              justify-content: space-between;
              width: 100%;
              font-size: 14px;

              .name-content {
                @include ellipsis();

                flex-grow: 1;
              }

              .name-assign {
                flex-grow: 0;
                flex-shrink: 0;
                margin-left: 15px;

                .el-button {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  min-width: auto;
                  width: 25px;
                  height: 25px;
                }
              }
            }

            .item-label {
              margin-right: 5px;
              color: #999;
            }

            &.item-belongs {
              &.user {
                color: #f56c6c;
              }

              &.group {
                color: #e6a23c;
              }

              &.public {
                color: #67c23a;
              }
            }
          }
        }
      }
    }
  }
</style>
